import resolveImage from "./resolveImage";

const resolveArticles = articles => {
  if (articles && articles.length > 0) {
    return articles.map(article => {
      const {
        uid,
        metaArticleTopics,
        metaArticleTypes,
        title,
        summary,
        metaAuthor,
        image0,
        postDate,
        url,
        uri,
      } = article;
      return {
        uid,
        topics: metaArticleTopics?.map(topic => topic.uid),
        types: metaArticleTypes?.map(type => type.uid),
        title,
        summary,
        author: metaAuthor[0]?.title || "Third Plateau",
        image: resolveImage(image0),
        date: postDate,
        url,
        link: uri,
      };
    });
  }
  return null;
};

export default resolveArticles;
